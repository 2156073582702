import {merge} from 'lodash-es';
import {BaseOctopusConfig} from '../../base.octopus-config';
import {defaultApiURL, defaultNodeURL} from './settings';

const overrideConfig = {
    configuration: {
        http: {
            apiUrl: defaultApiURL,
        },
        nodejs: defaultNodeURL,
    }
}

export default merge({}, BaseOctopusConfig, overrideConfig);