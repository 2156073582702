import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, inject, signal, ChangeDetectorRef, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest} from 'rxjs';

import {map, take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {DemoEndModalComponent} from '@modules/activities/core/player-components/demo-end-modal/demo-end-modal.component';
import {InstanceVariablesService} from 'shared/instance-variables.service';

type Platform = {
    new(task): Platform;
    updateDisplay: (data, success, error) => void;
    openUrl: (sTextId, success, error) => void;
    validate: (mode, success, error) => void;
}

declare var TaskProxyManager: any;
declare var Platform: Platform;

@Component({
    selector: 'app-demo-external',
    templateUrl: './demo-external.component.html'
})
export class DemoExternalComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('iframeViewport', {static: true}) iframeViewport: ElementRef;
    @ViewChild('iframeView') iframeView: ElementRef;
    public iframeUrl = signal<SafeResourceUrl>(null);
    public platform: Platform;
    public isSizeInitDone = false;

    protected activatedRoute = inject(ActivatedRoute);
    protected communicationCenter = inject(CommunicationCenterService);
    private sanitizer = inject(DomSanitizer);
    private dialog = inject(MatDialog);
    private router = inject(Router);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private instanceVariablesService = inject(InstanceVariablesService);
    private ngZone = inject(NgZone);

    private isFullscreen = false;
    private demoId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('demoId')));
    private querySource$ = this.activatedRoute.paramMap.pipe(map(params => params.get('mtm_source')));
    private backendDrivenIndex$ = this.activatedRoute.queryParams.pipe(map(params => +(params.index ?? 0)));
    private backendDrivenDemo$ = this.instanceVariablesService.instanceVariables$.pipe(map(instanceVariables => instanceVariables.get('demoUrls') ?? []));

    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(_event): void {
        this.isFullscreen = !this.isFullscreen;
        this.iframeSizeByRatio();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.iframeSizeByRatio();
    }

    /***** Lifecycle Hooks *****/

    ngOnInit(): void {
        const settingsUrlsDemos = this.lessonsConfigurationService.settings.urlsDemos;
        const defaultUrl = settingsUrlsDemos[0].url;

        combineLatest([this.demoId$, this.backendDrivenIndex$, this.backendDrivenDemo$]).subscribe(([demoId, index, backendDemoUrls]) => {
            this.platform = null;
            if (demoId === 'backend-driven') {
                this.iframeUrl.set(this.toIframeUrl(backendDemoUrls[index]));
            } else {
                const demoUrl = settingsUrlsDemos.find(u => u.id === demoId)?.url || defaultUrl;
                this.iframeUrl.set(this.toIframeUrl(demoUrl));
            }
        });

        // close sidebar panel when start activity
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(false);
        window.addEventListener('message', this.onMessageReceived, false);
    }

    ngAfterViewInit(): void {
        this.iframeSizeByRatio();
    }

    ngOnDestroy(): void {
        window.removeEventListener('message', this.onMessageReceived);
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(true);
    }

    public onIframeLoad(): void {
        if (this.lessonsConfigurationService.settings.APIPEMTask && !this.platform) {
            TaskProxyManager.getTaskProxy('taskIframe', (task) => {
                this.platform = new Platform(task);
                TaskProxyManager.setPlatform(task, this.platform);
                task.getMetaData((metaData) => {
                    this.platformLoad(task, this.platform, metaData);
                });
            }, true);
        }
    }

    /***** Logic *****/

    private openModal() {
        return this.dialog.open(
            DemoEndModalComponent, {
                panelClass: 'demo-end-modal-dialog-wrapper'
            }
        );
    }

    private toIframeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url.includes('?') ? url + '&log=1' : url + '?log=1');
    }

    /**
     * resize the image according to its frame/viewport (in reality it's container iframe will take the same size automatically)
     */
    private iframeSizeByRatio(): void {
        if (this.iframeView && !this.isFullscreen) {
            const iframe = this.iframeView.nativeElement;
            iframe.height = '';
            iframe.width = '';
            iframe.height = +this.iframeViewport.nativeElement.offsetHeight;
            iframe.width = +this.iframeViewport.nativeElement.offsetWidth;
            this.isSizeInitDone = true;
        }
    }

    private platformLoad(task, platform, metaData): void {
        this.platform.updateDisplay = (data, success, error) => {
            success();
        };

        this.platform.openUrl = (sTextId, success, error) => {
            success();
        };

        this.platform.validate = (mode, success, error) => {
            console.log('mode : ' + mode);
            if (mode === 'cancel') {
                console.warn('cancel');
                return;
            } else if (mode === 'nextImmediate') {
                console.warn(mode);
            } else if (mode === 'log') {
                // nothing
            } else {
                // ici on réussi l'activité et on ouvre la modale
                this.onActivityDone();
            }
        };

        const loadedViews = {'task': true, 'solution': true, 'hints': true, 'editor': true, 'grader': true, 'metadata': true, 'submission': true};
        task.load(loadedViews, () => {
            // loaded
        });
    }

    /***** Event Handlers *****/

    private onMessageReceived = (event) => {
        if (event.data === 'storyLine completed') {
            this.onActivityDone();
        }
    };

    private onActivityDone(): void {
        combineLatest([
            this.demoId$,
            this.querySource$,
            this.backendDrivenIndex$,
            this.backendDrivenDemo$
        ])
            .pipe(take(1))
            .subscribe(([demoId, source, index, backendDemoUrls]) => {
                const navigateToNextDemo = () =>
                    this.ngZone.run(() => this.router.navigate(['demo', 'backend-driven'], {queryParams: {index: index + 1}, queryParamsHandling: 'merge'}))

                const navigateToRegister = () => {
                    const queryParams = demoId === 'backend-driven' ? {mtm_campaign: 'tunnel-de-conversion'} : {};
                    queryParams['index'] = undefined
                    this.ngZone.run(() => this.router.navigate(['register'], {queryParams, queryParamsHandling: 'merge'}))
                };

                const handleModalClose = () => {
                    if (demoId === 'backend-driven' && index < backendDemoUrls.length - 1) {
                        navigateToNextDemo();
                    } else {
                        navigateToRegister();
                    }
                };

                this.openModal().afterClosed().pipe(take(1)).subscribe(handleModalClose);
            });
    }
}