import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {BasicPageService, PageEntity} from 'fuse-core/components/basic-page/basic-page.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AuthenticationService} from '@modules/authentication';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../app/settings';
import {get} from 'lodash-es';
import {ViewportScroller} from '@angular/common';

const settingsInfos: ModelSchema = new ModelSchema({
    pagesGar: Structures.array([
        {id: 'mentions-legales-gar', icon: 'policy-rounded-fill'},
        {id: 'cgurgpd-gar', icon: 'article-rounded-fill'},
        {id: 'politique-confidentialite', icon: 'lock-rounded-fill'},
        {id: 'accessibilite', icon: 'accessibility-rounded'},
        {id: 'feedback', icon: 'markunread-mailbox-rounded-fill'}]),
    pagesNotGar: Structures.array([
        {id: 'mentions-legales', icon: 'policy-rounded-fill'},
        {id: 'cgurgpd', icon: 'article-rounded-fill'},
        {id: 'politique-confidentialite', icon: 'lock-rounded-fill'},
        {id: 'accessibilite', icon: 'accessibility-rounded'},
        {id: 'feedback', icon: 'markunread-mailbox-rounded-fill'}]),

});

@Component({
    selector: 'app-informations-legales',
    templateUrl: './informations-legales.component.html',
})
export class InformationsLegalesComponent implements OnInit {
    public showLoader = false;
    private settings: { [key: string]: any };
    public pages: { icon: string, data: Observable<(PageEntity)> }[] = [];
    public pagesReady: { icon: string, data: PageEntity, title: SafeHtml, body: SafeHtml }[] = [];
    public title = '';
    public body = '';

    constructor(
        private translate: TranslateService,
        private pageService: BasicPageService,
        public domSanitizer: DomSanitizer,
        private auth: AuthenticationService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.settings = settingsInfos.filterModel(modulesSettings.infoLegales);

    }

    public ngOnInit(): void {
        this.showLoader = true;
        this.getPages();
    }

    getPages(): void {
        if (this.auth.isGAR()) {
            this.pages = this.pagesGar.map(p => {
                return {icon: p.icon, data: this.pageService.loadPage(p.id)};
            });
        } else {
            this.pages = this.pagesNotGar.map(p => {
                return {icon: p.icon, data: this.pageService.loadPage(p.id)};
            });
        }
        forkJoin(this.pages.map(page => page.data)).subscribe(data => {
            const icons = this.pages.map(page => page.icon);
            this.pagesReady = data.map((p, index) => {
                return {
                    data: p,
                    title: this.domSanitizer.bypassSecurityTrustHtml(p?.get('label')),
                    body: this.domSanitizer.bypassSecurityTrustHtml(p?.get('body')),
                    icon: icons[index]
                };
            });
            this.showLoader = false;
        });
    }

    public get pagesGar(): { id: string, icon: string }[] {
        return get(this.settings, 'pagesGar', []);
    }

    public get pagesNotGar(): { id: string, icon: string }[] {
        return get(this.settings, 'pagesNotGar', []);
    }

    focus(index: number): void {
        const element = document.getElementById('focus_' + index);
        if (element) {
            this.changeDetectorRef.detectChanges();
            element.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'});
        }
    }

}