import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-default-route',
    template: ``,
    standalone: true
})
export class DefaultRouteComponent implements OnInit {
    private authenticationService = inject(AuthenticationService);
    private router = inject(Router);
    private communicationCenter = inject(CommunicationCenterService);

    ngOnInit(): void {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(userData => {
            if (userData) {
                this.authenticationService.overrideDefaultRouteInRegardOfRole(true);
            }
        });
    }

}
