import { Component } from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatIcon} from '@angular/material/icon';
import {brand, brandLogoSvg} from 'app/settings';
import {FuseSharedModule} from 'fuse-core/shared.module';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    imports: [
        FlexModule,
        FuseSharedModule,
        MatIcon
    ],
    standalone: true
})
export class MaintenanceComponent {
  public brand = brand;
  public brandLogoSvg = brandLogoSvg;
}
