import {Injectable} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';
import {UserDataEntity} from "@modules/authentication";
import {TypedDataCollectionInterface, TypedDataEntityInterface} from 'shared/models/octopus-connect';

export interface EducationalLevelAttributesInterface {
    label: string;
    weight: string;
}

export type UserLevelInterface = EducationalLevelAttributesInterface & {
    id: string | number;
}

export type EducationalLevelEntity = TypedDataEntityInterface<EducationalLevelAttributesInterface>;
export type EducationalLevelCollection = TypedDataCollectionInterface<EducationalLevelAttributesInterface>;

interface GetEducationalLevelOptions {
    filters: {
        label: string;
    } | {
        id: string | number | (string | number)[];
    }
}

@Injectable({
    providedIn: 'root'
})
export class EducationalLevelService {

    private userData: UserDataEntity;

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
    ) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject<UserDataEntity>('userData')
            .subscribe((data) => {
                this.userData = data;
            });

        this.communicationCenter
            .getRoom('educational_levels')
            .next('getEducationalLevelCallback', () =>
                this.getEducationalLevels()
            );

    }

    public getEducationalLevels(options?: GetEducationalLevelOptions): Observable<EducationalLevelCollection> {
        return this.octopusConnect.loadCollection('educational_level', options?.filters) as Observable<EducationalLevelCollection>;
    }

    /**
     *  Return user progression for each concepts of the selected educational level
     */
    public getEducationalLevelUserProgression(selectedLevelId: string | number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('user-progress', {educationalLevel: selectedLevelId});
    }

    /**
     * Return current authenticated user's level, undefined if user is not logged in.
     * {@link setUserEducationalLevels}
     */
    public getUserLevels(): UserLevelInterface[] {
        if (this.userData) {
            return this.userData.get('level') as UserLevelInterface[];
        }
    }

    /**
     * Save educational levels Ids in user data
     * @param educationalLevelIds
     * {@link getUserLevels}
     * could be return an error if user are not connected
     */
    public setUserEducationalLevels(educationalLevelIds: (number | string)[]) {
        if (this.userData) {
            this.userData.set('level', educationalLevelIds);
            return this.userData.save().pipe(
                // level's field will be an array of id(string) because it is set before the .save() and we need the full value
                tap((userdata) => this.userData = userdata as UserDataEntity)
            );
        }
        throw new Error('Cannot set user\'s levels if you are not connected');
    }

    public getEducationalLevel(id: number | string): Observable<EducationalLevelEntity> {
        return this.octopusConnect.loadEntity('educational_level', id) as Observable<EducationalLevelEntity>;
    }
}
