import * as _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {NotepadRepositoryService} from '@modules/notepad/core/notepad-repository.service';
import {NotepadService} from '../notepad.service';
import {brand} from '../../../../settings';
import {takeUntil, tap} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {LangChangeEvent} from "@ngx-translate/core/lib/translate.service";

export type Action = 'show' | 'edit' | 'delete' | 'goToLesson'

@Component({
    selector: 'app-notepad-card',
    templateUrl: './notepad-card.component.html',
})
export class NotepadCardComponent implements OnInit {
    /**
     * Notepad resource used to get card data
     */
    @Input()
    public notepadBasicSearchEntity: DataEntity;

    @Input()
    public embedded: boolean;
    @Input()
    public forceAssociationOnSave: DataEntity;
    /**
     * Emit when granule has changed from this component and need to be reloaded
     */
    @Output()
    public hasChanged = new EventEmitter<void>();
    @Output()
    public actionTriggered = new EventEmitter<Action>();
    /**
     * Notepad creation date
     */
    public creationDate: string;
    /**
     * card image url (in the left side)
     */
    public imageUri: string;
    /**
     * Notepad last modification date
     */
    public lastUpdate: string;
    /**
     * Notepad associated lesson name
     */
    public associatedLessons: { title: string; id: string | number }[] = [];
    /**
     * Define if assiciated lesson name is loading from it's id
     */
    public associatedLessonIsLoading = true;
    /**
     * Define notepad title
     */
    public title: string;
    private notepadEntity: DataEntity;

    constructor(
        private notepadSvc: NotepadService,
        private notepadRepoSvc: NotepadRepositoryService,
        private translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.creationDate = new Date(
            this.notepadBasicSearchEntity.get('created') * 1000
        ).toLocaleDateString(this.translate.currentLang);
        this.lastUpdate = new Date(
            this.notepadBasicSearchEntity.get('changed') * 1000
        ).toLocaleDateString(this.translate.currentLang);

        this.translate.onLangChange.pipe(tap((langChangeEvent: LangChangeEvent) => {
            this.creationDate = new Date(
                this.notepadBasicSearchEntity.get('created') * 1000
            ).toLocaleDateString(langChangeEvent.lang);
            this.lastUpdate = new Date(
                this.notepadBasicSearchEntity.get('changed') * 1000
            ).toLocaleDateString(langChangeEvent.lang);
        })).subscribe();

        this.imageUri = this.getImageUri();
        this.title = this.notepadBasicSearchEntity.get('title');

        // For get the associated lesson title, we need to load the notepad activity content.
        // We don't have the activity content here and to have it, we need to load the granule and everything (incluce the activitycontent) is loaded with it.
        this.notepadRepoSvc
            .getNotepad(this.notepadBasicSearchEntity.id)
            .pipe(
                tap((notepad) => (this.notepadEntity = notepad)),
                // There are only one activity content and one assignated lesson by notepad
                tap((notepad) =>
                    this.associatedLessons = _.get(
                        notepad.get('reference'),
                        'activity_content[0].associated_nodes'
                    ) || []
                ),
                tap(() => (this.associatedLessonIsLoading = false))
            )
            .subscribe();
    }

    /**
     * Go to notepad editor.
     * No need to know how to edit a notepad here.
     * @param $event
     */
    public edit($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("edit");
        const forceAssociation = this.forceAssociationOnSave?.id || null;
        this.notepadSvc.goToNotepadDataEdition(this.notepadEntity, this.embedded, forceAssociation);
    }

    /**
     * Delete the current Notepad
     * @param $event
     */
    public delete($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("delete");
        this.notepadSvc
            .deleteNotepad(this.notepadEntity.id)
            .pipe(tap(() => this.hasChanged.next()))
            .subscribe();
    }

    /**
     * Display the content of the notepad
     * @param $event
     */
    public show($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("show");
        this.notepadSvc.displayNotepad(this.notepadBasicSearchEntity.id);
    }

    public goToLesson(id: string | number): void {
        this.actionTriggered.next("goToLesson");
        this.notepadSvc.goToLesson(id);
    }

    /**
     * Extracted way to know the notepad image urls
     */
    private getImageUri(): string {
        return './assets/' + brand + '/icons/illu_outil_note.svg';
    }

    dissociateLesson($event: MouseEvent, associatedLesson: { title: string; id: string | number }) {
        $event.stopPropagation()
        this.associatedLessonIsLoading = true
        this.notepadSvc.dissociateLesson(this.notepadEntity, associatedLesson).subscribe(() => {
            this.ngOnInit();
        })
    }
}
