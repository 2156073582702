import { CommunicationCenterModule } from '@modules/communication-center';
import { LrsService } from './lrs.service';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';


@NgModule({
    imports: [
        CommonModule,
        CommunicationCenterModule,
    ],
    providers: [],
    declarations: [

    ]
})
export class LrsModule {
    private static isMenuSet = false;

    constructor(
    ) {


    }

    static forRoot(): ModuleWithProviders<LrsModule> {

        return {
            ngModule: LrsModule,
            providers: [
                LrsService
            ]
        };
    }
}
