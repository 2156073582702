export const BaseOctopusConfig = {
    defaultInterface: 'localstorage',
    maxRetry: 5,
    retryTimeout: 50000,
    liveRefreshService: 'refresh',
    configuration: {
        localstorage: {
            prefix: 'test',
        },
        http: {
            /* Will be set by the application
            apiUrl: undefined,
             */
            useApiExtension: true,
            headers: {
                'Content-type': 'application/json',
            },
        },
        /* Will be set by the application
        nodejs: {
            socketUrl: undefined,
        },
        */
    },
    map: {
        achievements: {
            type: 'http',
            useLanguage: true,
        },
        contests: 'http',
        'contest-registration': 'http',
        'contest-tickets': 'http',
        'contest-progress': 'http',
        'contest-rank': 'http',
        establishments: 'http',
        'openbadges': {
            type: 'http',
            useLanguage: true,
        },
        'openbadges-awards-pdf': 'http',
        'form-project': 'http',
        feedback: 'http',
        bd: 'http',
        'log-book': 'http',
        users: 'http',
        user_search: 'http',
        learners: 'http',
        trainers: 'http',
        licenses: 'http',
        migrations: {
            type: 'http',
            useLanguage: true,
        },
        'licenses-import': 'http',
        groups: 'http',
        institutions: 'http',
        'user-registration': 'http',
        'reset-password': 'http',
        userfiles: 'http',
        projects: 'http',
        'contact-message': 'http',
        notification: {
            type: 'http',
            refreshEnabled: true,
        },
        'notification-status': 'http',
        corpus: 'http',
        lesson: 'http',
        activity: 'http',
        media: 'http',
        qcm: 'http',
        app: 'http',
        answer_app: 'http',
        assignations: {
            type: 'http',
            refreshEnabled: true,
        },
        'assignations-group': 'http',
        'event-assignation': 'http',
        'collections': 'http',
        granule: {
            type: 'http',
            refreshEnabled: true,
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
                reference: 'reference',
            },
            useLanguage: true,
        },
        difficulty: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        usage: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        schoolyears: 'http',
        'granule-activity': {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
                reference: 'activity',
            },
        },
        'granule-lesson': {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
        },
        'granule-sequence': {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
        },
        'granule-form': {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
        },
        'granule-format': {
            type: 'http',
            cached: false,
        },
        regions: {
            type: 'http',
            cached: false,
            useLanguage: true
        },
        tralabar_links: {
            type: 'http',
            cached: false,
        },
        educational_level: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        tags: {
            type: 'http',
            refreshEnabled: true,
        },
        bookmarks: 'http',
        'bookmarks-theme': 'http',
        consulted: 'http',
        consulted_assignation: 'http',
        evaluation: 'http',
        'corpus-text-ressource': 'http',
        'corpus-url-ressource': 'http',
        metadatas: 'http',
        'corpus-set': {
            type: 'http',
            embeddings: {
                'corpus-list': 'corpus',
            },
        },
        'research-sheets': {
            type: 'http',
            refreshEnabled: true,
        },
        sections: {
            type: 'http',
            refreshEnabled: true,
        },
        'draft-sections': {
            type: 'http',
            refreshEnabled: true,
        },
        'section-definitions-set': {
            type: 'http',
            embeddings: {
                sections: 'section-definitions',
            },
        },
        'section-definitions': {
            type: 'http',
            embeddings: {
                readingGrids: 'reading-grid',
            },
        },
        refresh: 'nodejs',
        comments: 'http',
        'user-progress': 'http',
        'user-save': {
            type: 'http',
            embeddings: {
                userActivity: 'user-activity',
            },
        },
        'last-user-save': {
            type: 'http',
            embeddings: {
                userActivity: 'user-activity',
            },
        },
        'user-activity': 'http',
        answer: 'http',
        'qcm-save': 'http',
        'ord-save': 'http',
        'app-save': 'http',
        'rb-save': 'http',
        genericsave: 'http',
        'reading-grids': 'http',
        'draft-questions': 'http',
        'logical-links-set': 'http',
        'logical-links': 'http',
        'ideas-wall': {
            type: 'http',
            refreshEnabled: true,
        },
        'wall-idea': {
            type: 'http',
            refreshEnabled: true,
        },
        'wall-category': {
            type: 'http',
            refreshEnabled: true,
        },
        ideas_wall_category: {
            type: 'http',
            cached: false,
        },
        'corpus_search': {
            type: 'http',
            refreshEnabled: true,
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
                reference: 'reference',
            },
            useLanguage: true,
        },
        basic_search: {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
                reference: 'activity',
            },
            useLanguage: true,
        },
        assignation_search: {
            type: 'http',
        },
        'assignations-batch': {
            type: 'http',
        },
        faq_search: {
            type: 'http',
            useLanguage: true,
            //cached: false
        },
        lesson_granule_search: {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
            useLanguage: true,
        },
        themes: {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
            useLanguage: true,
        },
        theme_search: {
            type: 'http',
            embeddings: {
                format: 'granule-format',
                metadatas: 'metadatas',
            },
            useLanguage: true,
        },
        'variables/instance': {
            type: 'http',
            useLanguage: true,
        },
        assignation_state: 'http',
        groups_search: {
            type: 'http',
        },
        'variables/postalCodes': {
            type: 'http',
        },
        chapters: {
            type: 'http',
            useLanguage: true,
        },
        assignation_type: {
            type: 'http',
            useLanguage: true,
        },
        skills: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        concepts: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        thematics: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        notions: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        authenticated: {
            type: 'nodejs',
        },
        'join-wall': {
            type: 'nodejs',
        },
        pages: {
            type: 'http',
            useLanguage: true,
        },
        'user-points': 'http',
        'badges-type': 'http',
        badges: {
            type: 'http',
            useLanguage: true,
        },
        levels: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        'users-import': 'http',
        'classes-import': 'http',
        threads: {
            type: 'http',
        },
        messages: {
            type: 'http',
            refreshEnabled: true,
        },
        'error-reporting': {
            type: 'http',
            useLanguage: true,
        },
        events: {
            type: 'http',
            useLanguage: true,
        },
        timeline: {
            type: 'http',
        },
        marker_type: {
            type: 'http',
            cached: false,
            useLanguage: true,
        },
        video_marker: 'http',
        reviews: 'http',
        summary: 'http',
        'user-dashboard': 'http',
        progress: 'http',
        metrics: 'http',
        mindmaps: 'http',
        creations: 'http',
        notepads: 'http',
        homepage_lms: {
            type: 'http',
            useLanguage: true,
        },
        'variables/helpVideos': 'http',
        'comments-list': {
            type: 'http',
            useLanguage: true,
        },
        feedbacks: 'http',
        recaptchav3: {
            type: 'http',
        },
        'statements-analytics': 'http',
        recommendations: 'http',
        'users-link': 'http',
        activity_type: 'http',
        'statements-stats': 'http',
        'learners-stat': 'http',
        'user-role-update': 'http',
        leaderboard: 'http',
        'page-table': 'http',
        'page-views': 'http',
        'survey-config': 'http',
        'survey': 'http',
        'survey-link': 'http',
        'survey-save': 'http',
        'thematique-boussole': 'http',
        'ressource-boussole': 'http',
        'level-boussole': 'http',
        'explore': 'http',
        'diagnostic': 'http',
        'skills-boussole': 'http',
        'difficulty-boussole': 'http',
        'pedago': 'http',
        'organisation': 'http',
        'nps': 'http',
        'user-review': 'http',
        'flashcard': 'http',
        'recording': 'http',
        'awareness': 'http',
        'rb': 'http',
    },
} as const;
