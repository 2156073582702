import {inject, Injectable} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivityCreationService} from '@modules/activities/core/activity-creation.service';
import {LessonInterface, MediaInterface, MetadataInterface} from '@modules/activities/core/lessons/lessons-interfaces.interface';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {GranuleEntity} from 'shared/models/granule';

@Injectable({
  providedIn: 'root'
})
export class LessonCreationService {

    private activityCreationService = inject(ActivityCreationService);
    private activitiesService = inject(ActivitiesService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private octopusConnect = inject(OctopusConnectService);
    private communicationCenter = inject(CommunicationCenterService);

    constructor() {
        this.communicationCenter.getRoom('lessonCreation').next('createLessonGranule', this.createLessonGranule.bind(this));
    }

    public createLessonGranule(defaultData: Partial<LessonGranuleEntity['attributes']>,metadata: Partial<MetadataInterface>, type: 'lesson', forCopy = false, subLesson = null) {

        if (this.lessonsConfigurationService.settings.lessonStep && !subLesson) {

            const obsContainer = this.activityCreationService.createActivitiesByTypes(this.lessonsConfigurationService.settings.lessonStep.typeSteps);

            return combineLatest(obsContainer).pipe(
                take(1),
                mergeMap(entities => {
                    const obsSummary: Observable<DataEntity>[] = [];
                    const entitySummary: DataEntity[] = [];
                    if (this.lessonsConfigurationService.settings.lessonStep && this.lessonsConfigurationService.settings.lessonStep.typeSteps) {
                        obsSummary.push(
                            ...this.lessonsConfigurationService.settings.lessonStep.typeSteps.map((summary, index) => {
                                if (summary.type === 'SUMMARY') {
                                    entitySummary.push(entities[index]);
                                    if (entities[index].get('format') && entities[index].get('format').label === 'activity') {
                                        const summaryData: MediaInterface = {
                                            granule: [entities[summary.dataFromStep.stepIndex].id]
                                        };
                                        return this.createSummary(summaryData);
                                    }
                                }
                            }).filter((val) => val)
                        );
                    }
                    if (obsSummary.length > 0) {
                        const obsAllActivityRef = entitySummary.map((entity) => {
                            return this.activitiesService.loadActivityInterface(entity.get('reference'));
                        });

                        return combineLatest(obsSummary).pipe(
                            mergeMap((entitiesSummary: DataEntity[]) => {
                                return combineLatest(obsAllActivityRef).pipe(
                                    mergeMap((refs: DataEntity[]) => {
                                        const obsActivityContent = entitiesSummary.map((summary, index) => {
                                            refs[index].set('activity_content_patch', summary.id);
                                            return refs[index].save();
                                        });

                                        return combineLatest(obsActivityContent).pipe(
                                            mergeMap(() => this.createLessonAndGranule(defaultData, metadata, forCopy, entities, type))
                                        );
                                    })
                                );
                            })
                        );
                    } else {
                        return this.createLessonAndGranule(defaultData, metadata, forCopy, entities, type);
                    }
                }));

        } else {
            const lessonObs = this.createActivitiesLesson({lesson_step: subLesson ? subLesson.map((entity) => +entity.id) : []});
            return this.createBundleGranule<LessonGranuleEntity>(defaultData, metadata, forCopy, lessonObs, type);
        }
    }

    createBundleGranule<T extends GranuleEntity<any>>(defaultData: Partial<T['attributes']>, metadata, forCopy: boolean, lessonObs: Observable<LessonGranuleEntity>, type: 'lesson') {
        return this.createTags(metadata.indexation, forCopy).pipe(mergeMap((tags: DataEntity[]) => {
            const originalTags = metadata.indexation;
            metadata.indexation = tags.map((tag) => +tag.id);
            metadata.chapters = [metadata.chapters];
            const metadataObs = this.activityCreationService.createMetadata(metadata);
            metadata.indexation = originalTags;

            const combined = combineLatest([lessonObs, metadataObs]).pipe(take(1));

            const notNullDefaultData = {...defaultData};

            return combined.pipe(mergeMap((entities: DataEntity[]) => {
                return this.activityCreationService.createGranule<T>({
                    format: this.activitiesService.getFormatId(type),
                    reference: +entities[0].id,
                    metadatas: +entities[1].id,
                    theme: metadata.theme,
                    usage: metadata.usage,
                    ...notNullDefaultData,
                });
            }));
        }));
    }

    public createSummary(Granule: MediaInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('summary', Granule).pipe(take(1));
    }

    public createLessonAndGranule(defaultData: Partial<LessonGranuleEntity['attributes']>, metadata: MetadataInterface, forCopy: boolean, entities: { id: string | number }[], type: 'lesson') {
        const lessonObs = this.createActivitiesLesson({lesson_step: entities.map((entity) => +entity.id)}) as Observable<LessonGranuleEntity>;
        return this.createBundleGranule<LessonGranuleEntity>(defaultData, metadata, forCopy, lessonObs, type);
    }

    private createActivitiesLesson(LessonStep: LessonInterface) {
        return this.octopusConnect.createEntity('lesson', LessonStep).pipe(take(1)) as Observable<LessonGranuleEntity>;
    }

    private createTags(tags: { id: string }[], forCopy = false): Observable<DataEntity[]> {
        if (tags && tags.length > 0) { // if indexation keywords
            const tagObsArray: Observable<DataEntity>[] = [];
            let tagObs;

            tags.forEach((chip) => {
                if (!chip.id || forCopy) { // if tag not in DB
                    tagObs = this.octopusConnect.createEntity('tags', chip).pipe(take(1));
                    tagObs.subscribe((data: DataEntity) => { // create tag
                        chip.id = data.id.toString(); // add id to object
                    });
                    tagObsArray.push(tagObs);
                }
            });

            if (tagObsArray.length !== 0) {
                return combineLatest(tagObsArray);
            } else {
                const placeholder = new ReplaySubject<DataEntity[]>(1);
                placeholder.next([]);
                return placeholder;
            }
        } else {
            const placeholder = new ReplaySubject<DataEntity[]>(1);
            placeholder.next([]);
            return placeholder;
        }
    }
}
