import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from 'fuse-core/animations';
import {MatTableDataSource} from '@angular/material/table';
import {CollectionOptionsInterface, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {of, Subscription} from 'rxjs';
import {AssignationService} from '@modules/assignation';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable} from 'rxjs/index';
import {filter, map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {IndexService} from 'fuse-core/services/index.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-assignation-groups-list',
    templateUrl: './assignation-groups-list.component.html',
    styleUrls: ['./assignation-groups-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AssignationGroupsListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {

    public displayedColumns: string[] = ['lesson', 'startDate', 'learnersCount', 'questionSet', 'status', 'unassign'];
    public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
    expandedElement: IAssignationGroup | null;

    public get displayedFilters(): string[] {
        return [];
    }

    resources;
    dataSource = new MatTableDataSource();
    private optionsInterface: CollectionOptionsInterface;
    public allTypes = [];
    public isLoading = true;
    private subscribeData = new Subscription();
    countEntities = 10;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];
    private currentUser: UserDataEntity;
    public assignationGroupsPaginated: PaginatedCollection;
    private assignationGroups: IAssignationGroup[] = [];
    public selectedCollection = null;
    private currentIndex = -1;

    constructor(public assignationService: AssignationService,
                private authService: AuthenticationService,
                private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService,
                private indexService: IndexService,
                private dialog: MatDialog,
                private translate: TranslateService,
    ) {
        super();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                this.currentUser = data;
            });

        this.optionsInterface = {
            filter: this.authService.accessLevel === 'learner' ? {'assignated_user': this.authService.userData.id} : {'assignator': this.currentUser.id},
            page: 1,
            range: 10
        };
    }

    ngOnInit(): void {
        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
        });
        //ctz-setting
        if (this.assignationService.loadRootFilter()) {
            this.communicationCenter
                .getRoom('root-filter')
                .getSubject('selected')
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(selectedCollection => {
                    if (selectedCollection !== undefined && selectedCollection !== null) {
                        this.selectedCollection = selectedCollection;
                    }

                    // only after having filter value
                    if (this.shouldDisplayFilters() === false) {
                        return this.fixNoDataRequest();
                    }
                });
        } else {
            if (this.shouldDisplayFilters() === false) {
                return this.fixNoDataRequest();
            }
        }
    }

    ngOnDestroy(): void {
        this.closeSubscribe();
        this.unsubscribe();
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    launchSearch(options: CollectionOptionsInterface = null): void {
        this.optionsInterface = options;
        //ctz-setting
        if (this.assignationService.loadRootFilter() && this.selectedCollection !== undefined && this.selectedCollection !== null) {
            this.indexService.pythonIdInRegardOfIndex(this.selectedCollection.toString())
                .pipe(take(1))
                .subscribe(concept => {
                    this.optionsInterface.filter['concepts'] = concept;
                    this.loadData();
                });
        } else {
            this.loadData();
        }
    }

    loadData(): void {
        this.isLoading = true;
        this.closeSubscribe();
        this.subscribeData = this.loadPaginatedAssignationGroups(this.optionsInterface)
            .subscribe((res: DataEntity[]) => {
                this.formatData(res);
                this.dataSource.data = this.assignationGroups;
                this.isLoading = false;
                this.setPaginator();
            });
    }

    private loadPaginatedAssignationGroups(filterOptions: CollectionOptionsInterface = {}): Observable<Object[]> {
        this.assignationGroupsPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
        return this.assignationGroupsPaginated.collectionObservable.pipe(map(collection => collection.entities));
    }

    private closeSubscribe(): void {
        if (this.subscribeData) {
            this.subscribeData.unsubscribe();
        }
    }

    setPaginator(): void {
        if (this.assignationGroupsPaginated.paginator) {
            this.countEntities = this.assignationGroupsPaginated.paginator.count;
            this.pageIndex = this.assignationGroupsPaginated.paginator.page - 1;
            this.pageRange = this.assignationGroupsPaginated.paginator.range;
        }
    }

    /**
     * format data
     * @param res dataentity array with data not formated
     */
    private formatData(res: DataEntity[]): void {
        this.assignationGroups = [];
        res.forEach(log => {
            this.assignationGroups.push({
                lesson: log.get('lesson'),
                startDate: +(log.get('startDate')) > 0 ? log.get('startDate') : log.get('created'),
                learners: Object.keys(log.get('assignations')),
                assignations: log.get('assignations'),
                status: this.getAssignationsStatus(log.get('assignations')),
                questionSet: log.get('questionSet')
            });
        });
    }

    private fixNoDataRequest(): void {
        this.launchSearch({
            filter: this.authService.accessLevel === 'learner' ?
                {'assignated_user': this.authService.userData.id} :
                {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        });
    }

    onPaginateChange(event): void {
        this.isLoading = true;
        this.assignationGroupsPaginated.paginator.page = event.pageIndex + 1;
    }

    getAssignationsStatus(assignations: Assignations): string {
        let isAssigned = false;
        let isInProgress = false;
        let isComplete = false;

        for (const key in assignations) {
            if (assignations.hasOwnProperty(key)) {
                const assignation = assignations[key];
                const status = this.getAssignationStatus(assignation);

                switch (status) {
                    case 'assignment.state.assigned':
                        isAssigned = true;
                        break;
                    case 'assignment.state.pending':
                        isInProgress = true;
                        break;
                    case 'assignment.state.valid':
                        isComplete = true;
                        break;
                }
            }
        }

        if (isInProgress) {
            return 'assignment.state.pending';
        } else if (isAssigned && isComplete) {
            return 'assignment.state.pending';
        } else if (isAssigned) {
            return 'assignment.state.assigned';
        } else {
            return 'assignment.state.valid';
        }
    }


    getAssignationStatus(assignation: Assignation): string {
        if (assignation['ratings'].every((value) => value === 0)) {
            return 'assignment.state.assigned';
        }
        if (assignation['ratings'].every((value) => value === 1)) {
            return 'assignment.state.valid';
        }
        if (assignation['ratings'].some((value) => value > 0)) {
            return 'assignment.state.pending';
        }
        return 'assignment.state.assigned';
    }

    public unAssign(assignation: Assignation, id: any, learner: any): void {
        if (this.dataSource.data[this.currentIndex]['learners'].length === 1) {
            // last assignation we use remove all instead of one by one
            this.unAssignAll(<IAssignationGroup>this.dataSource.data[this.currentIndex]);
            return;
        }
        let dialogTitle = '';
        let tooltipDeAssign = '';
        let dialogBody = '';
        let dialogYes = '';
        let dialogNo = '';
        this.translate
            .get('assignment.unassign')
            .subscribe((translation: string) => (dialogTitle = translation));
        this.translate
            .get('assignment.confirm_unassign')
            .subscribe((translation: string) => (dialogBody = translation));
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (dialogNo = translation));

        // get assignment dataEntity and open modal confirm
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: dialogTitle,
            bodyDialog: dialogBody,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo,
        };

        this.dialog
            .open(FuseConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((confirm) => confirm),
                mergeMap((assignment) => this.removeAssignment(assignation?.id))
            )
            .subscribe(res => {
                if (res === true) {
                    // suppress in assignations the key and in the learners list array and update data of table
                    delete this.dataSource.data[this.currentIndex]['assignations'][learner];
                    this.dataSource.data[this.currentIndex]['learners'] = [...this.dataSource.data[this.currentIndex]['learners'].filter(l => l !== learner)];
                }
            });
    }

    public unAssignAll(data: IAssignationGroup): void {
        let dialogTitle = '';
        let tooltipDeAssign = '';
        let dialogBody = '';
        let dialogYes = '';
        let dialogNo = '';
        this.translate
            .get('assignment.unassign_all')
            .subscribe((translation: string) => (dialogTitle = translation));
        this.translate
            .get('assignment.confirm_unassign')
            .subscribe((translation: string) => (dialogBody = translation));
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (dialogNo = translation));

        // get assignment dataEntity and open modal confirm
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: dialogTitle,
            bodyDialog: dialogBody,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo,
        };

        this.dialog
            .open(FuseConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((confirm) => confirm),
                mergeMap((assignment) => this.removeAllAssignment(data.assignations))
            )
            .subscribe(res => {
                if (res === true) {
                    // suppress all assignment data in local
                    const index = this.dataSource.data.indexOf(data);
                    this.dataSource.data[index]['assignations'] = {};
                    this.dataSource.data[index]['learners'] = [];
                    this.dataSource.data.splice(index, 1);
                    if (this.dataSource.data.length === 0) {
                        this.loadData();
                    } else {
                        this.countEntities = this.countEntities - 1;
                        this.assignationGroupsPaginated.paginator.count = this.countEntities;
                        this.pageRange = this.pageRange - 1;
                        this.dataSource._updateChangeSubscription();
                    }
                }
            });
    }

    private removeAssignment(assignmentId: string): Observable<boolean> {
        return this.assignationService.deleteAssignmentById(assignmentId);
    }

    private removeAllAssignment(assignations: Assignations[]): Observable<boolean> {
        const ids = Object.values(assignations).map((item: any) => item.id);
        return this.assignationService.removeMultipleAssignment(ids);
    }


    /**
     * check if we can unassign all row on one shoot
     * @param data : data contain value to check to know if one user as progress increased or not
     */
    public cantBeDeAssign(data: IAssignationGroup): boolean {
        const assignations = <any>Object.values(data.assignations);
        for (let ass of assignations) {
            if (+ass?.progress > 0) {
                return true;
            }
        }
        return false;
    }

    handleRowClick(assignation: IAssignationGroup, expanded: boolean): void {
        if (expanded) {
            this.currentIndex = this.dataSource.data.indexOf(assignation);
        } else {
            this.currentIndex = -1;
        }
    }
}

interface Assignation {
    id: string;
    progress?: string;
    username?: string;
}

interface Assignations {
    [key: string]: Assignation;
}

interface IAssignationGroup {
    lesson: string;
    learners: string[];
    status: string;
    startDate?: string;
    assignations?: Assignations[];
    questionSet?: string;
}