import {HttpClient} from '@angular/common/http';
import {CommunicationCenterService} from '@modules/communication-center';
import {inject, Injectable} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import XAPI, {Actor, Statement, Verb, Result, Activity} from '@xapi/xapi';
import {Extensions} from '@xapi/xapi/dist/types/resources/statement/Extensions';
import {LanguageService} from 'shared/language.service';

export class CustomVerbs extends XAPI.Verbs {
    static readonly EXPERIENCED: Verb = {
        id: "http://adlnet.gov/expapi/verbs/experienced",
        display: {
            "en-US": "experienced",
        },
    };
    static readonly PLAYED: Verb = {
        id: "http://adlnet.gov/expapi/verbs/played",
        display: {
            "en-US": "played",
        },
    };
    static readonly ENDED: Verb = {
        id: "http://adlnet.gov/expapi/verbs/ended",
        display: {
            "en-US": "ended",
        },
    };
        static readonly DOWNLOADED: Verb = {
        id: "http://adlnet.gov/expapi/verbs/downloaded",
            display: {
            "en-US": "downloaded",
        },
    };
}

const settingsStructure: ModelSchema = new ModelSchema({
    api_endPoint: Structures.string(),
    authorization_token: Structures.string()
});

interface ActivityLrsData {
    activity: DataEntity,
    id: string | number,
    result?: any
    assignmentId ?: string | number
}

interface LessonLrsData {
    lessonId: string | number,
    lesson: DataEntity,
    concepts?: any,
    preview: boolean
}

interface VideoLrsData {
    id: string | number,
    src: string,
    title: string
}

interface AudioLrsData {
    id: string | number,
    src: string,
    title: string
}

interface MediaLrsData {
    id: string | number,
    type: string,
    title: string,
    src?: string
}

@Injectable({
    providedIn: 'root'
})
export class LrsService {
    public settings: { api_endPoint: string, authorization_token: string };
    private languageService = inject(LanguageService);

    userId = '0'; // Drupal user id for all statements
    actor: Actor = {
        objectType: 'Agent',
        name: '0',
        mbox: `mailto:0@tralalere.com`,
    };
    userUai: string;
    commune: string;
    private xapi: XAPI;

    constructor(
        private communicationService: CommunicationCenterService,
        httpService: HttpClient
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.lrs) as LrsService['settings'];
        this.xapi = new XAPI({endpoint: this.settings.api_endPoint, auth: this.settings.authorization_token});
        this.communicationService
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userUai = null;
                    this.commune = null;
                    if (data.get('sso_token') && data.get('sso_token').hasOwnProperty("UAI")) {
                        this.userUai = data.get('sso_token').UAI;
                    }
                    if (data.get('config') && data.get('config').hasOwnProperty("commune")) {
                        this.commune = data.get('config').commune;
                    }
                    this.userId = data.id.toString();
                    this.actor.name = data.id.toString();
                    this.actor.mbox = `mailto:${this.userId}@tralalere.com`;
                } else {
                    this.userId = '0';
                }
            });

        const toAssignmentExtension = (id: string | number) => ({
            'https://lrs.tralalere.com/data/xAPI/assignation': id,
            'https://lrs.tralalere.com/data/xAPI/extensions/type': 'activity',
            "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
            "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
        });

        this.communicationService
            .getRoom('lrs')
            .getSubject('media_downloaded')
            .subscribe((data: MediaLrsData) => {
                if (data.id) {
                    const name = data.title;
                    const verb = CustomVerbs.DOWNLOADED;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": data.type,
                        "https://lrs.tralalere.com/data/xAPI/extensions/src": data.src,
                    }
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('audio_played')
            .subscribe((data: AudioLrsData) => {
                if (data.id) {
                    const verb = CustomVerbs.PLAYED;
                    const name = data.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/src": data.src ? data.src : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'audio',
                    }
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('audio_ended')
            .subscribe((data: AudioLrsData) => {
                if (data.id) {
                    const verb = CustomVerbs.ENDED;
                    const name = data.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/src": data.src ? data.src : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'audio',
                    }
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('video_played')
            .subscribe((data: VideoLrsData) => {
                if (data.id) {
                    const verb = CustomVerbs.PLAYED;
                    const name = data.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/src": data.src ? data.src : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'video',
                    }
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('video_ended')
            .subscribe((data: VideoLrsData) => {
                if (data.id) {
                    const verb = CustomVerbs.ENDED;
                    const name = data.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/src": data.src ? data.src : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'video',
                    }
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('lesson_init')
            .subscribe((data: LessonLrsData) => {
                if (data.lessonId) {
                    const verb = XAPI.Verbs.INITIALIZED;
                    const name = data.lesson.attributes.metadatas.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/concepts": data.concepts ? data.concepts.map((concept) => concept.label) : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/previewMode": data.preview,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'lesson',
                    }
                    const statement = this.prepareStatement(data.lessonId, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('lesson_end')
            .subscribe((data: LessonLrsData) => {
                if (data.lessonId) {
                    const verb = XAPI.Verbs.TERMINATED;
                    const name = data.lesson.attributes.metadatas.title;
                    const objectExtensions= {
                        "https://lrs.tralalere.com/data/xAPI/extensions/concepts": data.concepts = data.concepts.map((concept) => concept.label),
                        "https://lrs.tralalere.com/data/xAPI/extensions/uai": this.userUai ? this.userUai : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/commune": this.commune ? this.commune : null,
                        "https://lrs.tralalere.com/data/xAPI/extensions/previewMode": data.preview,
                        "https://lrs.tralalere.com/data/xAPI/extensions/type": 'lesson',
                    }
                    const statement = this.prepareStatement(data.lessonId, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_attempt')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    const name = data.activity ? data.activity.attributes.metadatas.title : null;
                    const verb = XAPI.Verbs.ANSWERED;
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const statement = this.prepareStatement(data.id, verb, data.result, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_initialize')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    const name = data.activity ? data.activity.attributes.metadatas.title : null;
                    const verb = XAPI.Verbs.INITIALIZED;
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const statement = this.prepareStatement(data.id, verb, null, objectExtensions, name);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_complete')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    const verb = XAPI.Verbs.TERMINATED;
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const statement = this.prepareStatement(data.id, verb, data.result, objectExtensions);
                    this.postStatement(statement);
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_resume')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    const verb = XAPI.Verbs.RESUMED;
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const resumed = this.prepareStatement(data.id, verb, null, objectExtensions);
                    this.postStatement(resumed).then(() => {
                        // Selon la norme tincan un initialized DOIT suivre un resumed
                        const initVerb = XAPI.Verbs.INITIALIZED;
                        const initialized = this.prepareStatement(data.id, initVerb, null, objectExtensions);
                        this.postStatement(initialized);
                    });
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_open')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    // Verb openend is not in the XAPI Lib shortcuts
                    const verb = {
                        'id': 'http://adlnet.gov/expapi/verbs/opened',
                        'display': {
                            'en-US': 'opened'
                        }
                    };
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const resumed = this.prepareStatement(data.id, verb, null, objectExtensions);
                    this.postStatement(resumed).then(() => {
                        // Selon la norme tincan un initialized DOIT suivre un resumed
                        const initVerb = XAPI.Verbs.INITIALIZED;
                        const initialized = this.prepareStatement(data.id, initVerb, null, objectExtensions);
                        this.postStatement(initialized);
                    });
                }
            });

        this.communicationService
            .getRoom('lrs')
            .getSubject('activity_suspend')
            .subscribe((data: ActivityLrsData) => {
                if (data.id) {
                    const verb = XAPI.Verbs.SUSPENDED;
                    const objectExtensions = !!data.assignmentId ? toAssignmentExtension(data.assignmentId) : null;
                    const suspend = this.prepareStatement(data.id, verb, null, objectExtensions);
                    this.postStatement(suspend).then(() => {
                        // terminated indicates that the user quitted the activity (stops futher tracking)
                        const terminateVerb = XAPI.Verbs.TERMINATED;
                        const terminated = this.prepareStatement(data.id, terminateVerb, null, objectExtensions);
                        this.postStatement(terminated);
                    });
                }
            });
    }

    /**
     *  Statement activité avec résultat
     * {
	"timestamp": "2015-12-18T12:17:00+00:00",
	"actor":{
        "objectType": "Agent",
		"name":"Example Learner",
		"mbox":"mailto:example.learner@adlnet.gov"
	},
	"verb":{
		"id":"http://adlnet.gov/expapi/verbs/attempted",
		"display":{
			"en-US":"attempted"
		}
	},
	"object":{
		"id":"http://example.adlnet.gov/xapi/example/simpleCBT",

TODO :
VOIR https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#appendix-c-example-definitions-for-activities-of-type-cmiinteraction SI ON A des definitions intérrésantes


		"definition":{
			"name":{
				"en-US":"simple CBT course"
			},
			"description":{
				"en-US":"A fictitious example CBT course."
			}
		}
	},
	"result":{
		"score":{
			"scaled":0.95
		},
		"success":true,
		"completion":true,
		"duration": "PT1234S"  ISO8601
	}
}
     *
     *
     */

    postStatement(statement: Statement): Promise<any> {
        if (this.actor.name !== '0') {
            return this.xapi.sendStatement({statement: statement});
        }
    }

    private prepareStatement(activityId: string | number, verb: Verb, result?: Result, objectExtensions?: Extensions, name?: string): Statement {
        const activityObject: Activity = {
            id: `https://lrs.tralalere.com/data/xAPI/${activityId}`,
            objectType: 'Activity',
        }

        const statement: Statement = {
            actor: this.actor,
            verb: verb,
            object: activityObject,
        };

        if (result) {
            statement['result'] = result;
        }

        if (!!objectExtensions) {
            activityObject.definition = {
                extensions: objectExtensions,
            }
        }

        if (!!name) {
            const lang = this.languageService.getActiveLanguage().code;
            activityObject.definition = {
                ...activityObject.definition,  // Conserver les propriétés existantes
                name: {
                    [lang]: name
                }
            }
        }
        return statement;
    }
}
