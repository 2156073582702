import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {
    Institution,
    InstitutionAdresseDataCollection,
    InstitutionDataCollection,
    InstitutionDataEntity,
    InstitutionDataEntityAttributes,
    InstitutionGroup,
    InstitutionGroupAddress
} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {localizedDate} from 'shared/utils/datetime';
import {map, tap} from 'rxjs/operators';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import * as _ from 'lodash-es';
import {FilterData} from 'octopus-connect/lib/models/types';

const INSTITUTION_MANAGER_ROLE_ID = '5';
const INSTITUTION_EDUCATOR_ROLE_ID = '4';

@Injectable()
export class InstitutionGroupService {

    public paginatedCollection: PaginatedCollection;
    public institutionGroupsSubscription: Subscription = null;
    public onInstitutionGroupsChanged: BehaviorSubject<InstitutionGroup[]> = new BehaviorSubject([]);
    public institutionInUserSubscription: Subscription = null;
    public optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };
    private userData: DataEntity;
    private institutionGroupsCollection: { [key: number]: DataEntity } = {};
    private institutionsInUserDataCollection: { [key: number]: InstitutionDataEntity } = {};
    private institutionInUserData: Array<InstitutionGroup> = [];
    private institutionsInUserDataFormated: Array<Institution> = [];
    private allInstitutions: InstitutionGroupAddress[] = [];
    private allInstitutionsFormated: Array<Institution>;
    private postalCodes: Array<unknown>;
    private refreshListCallback: () => void;

    constructor(private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService) {

        this.communicationCenter
            .getRoom('groupsManagement')
            .next('isUserHasEducatorRightsInHisInstitutionsCallback',
                (user: UserDataEntity) => this.isUserHasEducatorRightsInHisInstitutions$(user)
            );

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('userProfileEdit')
            .getSubject('userData').subscribe((data) => {
            this.getUserInstitutions();
        });

        // to force reload of data beacause if we change data for example leaving or joining an  institution
        // we need to refresh data with the new one
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('refresh-institutionList').subscribe((data) => {
            this.getUserInstitutions();
        });

        // for getting institutions filtered
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutions$')
            .subscribe(({filter, callback}) => {
                callback(this.institutionsFiltered$(filter));
            });

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('loadEntityInstitution')
            .pipe(
                tap((data: {id: string, callback: (string) => Observable<DataEntity>}) => data.callback(this.loadInstitutionEntity(data.id)))
            )
            .subscribe();

    }

    /**
     * get institution observable filtered
     * @param filter : for example filter = {id: code};
     * @private
     */
    private institutionsFiltered$(filter: unknown): Observable<InstitutionDataCollection> {
        return this.octopusConnect.loadCollection('institutions', filter) as Observable<InstitutionDataCollection>;
    }

    private loadInstitutionEntity(id: string): Observable<InstitutionDataEntity> {
        return this.octopusConnect.loadEntity('institutions', id) as Observable<InstitutionDataEntity>;
    }


    public get groups(): InstitutionGroupAddress[] {
        return this.allInstitutions.slice();
    }

    getAllInstitutionsFormated(): Array<Institution> {
        return this.allInstitutionsFormated;
    }

    getInstitutionGroupAdresses(optionsInterfarce): Observable<InstitutionAdresseDataCollection> {
        this.paginatedCollection = this.octopusConnect.paginatedLoadCollection('groups_search', optionsInterfarce);

        return this.paginatedCollection.collectionObservable as Observable<InstitutionAdresseDataCollection>;

    }

    getUserInstitutions(): void {

        this.institutionInUserSubscription = this.getInstitutionGroupInUserFromServer()
            .subscribe((data) => {
                this.institutionInUserData = [];
                this.institutionsInUserDataFormated = [];
                if (data && data.entities) {
                    for (const entity of data.entities) {
                        const institution: InstitutionGroup = {
                            id: parseInt(entity.id.toString(), 10),
                            institutiongroupname: entity.get('label'),
                            address: this.getSubEntity('address', entity.get('address')),
                            city: this.getSubEntity('city', entity.get('address')),
                            postalCode: this.getSubEntity('postalCode', entity.get('address')),
                            country: this.getSubEntity('country', entity.get('address')),
                            locked: entity.get('locked'),
                            created: entity.get('created') ? localizedDate(+entity.get('created')) : '',
                            hideCode: (entity.get('hideCode') ? entity.get('hideCode') : null),
                            hideInstCode: (entity.get('hideInstCode') ? entity.get('hideInstCode') : false) as boolean,
                            code: (entity.get('code') ? entity.get('code') : entity.id).toString(),
                            userscounts: (entity.get('userscounts') ? entity.get('userscounts') : {}),
                            admins: entity.get('admins'),
                            license: entity.get('license'),
                            uai: entity.get('uai')
                        };

                        this.institutionsInUserDataCollection[entity.id] = entity;

                        // used to show all institution entities in User DATA.
                        this.institutionInUserData.push(institution);

                        // used to show all institution entities in User DATA FORMATED.
                        this.institutionsInUserDataFormated.push(this.getAllFields(institution));
                    }

                    this.communicationCenter
                        .getRoom('groups-management')
                        .next('institutionList', this.institutionInUserData);
                    this.communicationCenter
                        .getRoom('groups-management')
                        .next('institutionEntitiesList', data.entities);
                    this.communicationCenter
                        .getRoom('groups-management')
                        .next('institutionListFiltered', this.institutionsInUserDataFormated);
                    this.onInstitutionGroupsChanged.next(this.institutionInUserData);
                }
            });
    }

    getPostalCodeCollection(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('variables/postalCodes');

    }

    getInstitutionGroupInUserFromServer(filter: FilterData = {}) {
        return this.octopusConnect.loadCollection<InstitutionDataEntityAttributes>('institutions', filter);
    }

    getMetadata() {

        return {
            postalCodes: this.postalCodes ? this.postalCodes : [],
            country: [],
        };
    }

    launchSearch(callback: () => void, optionsInterface: CollectionOptionsInterface): void {
        this.refreshListCallback = callback;
        for (const field in optionsInterface) {
            this.optionsInterface[field] = optionsInterface[field];
        }

        if (!this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription = this.refreshList(optionsInterface);
        } else {
            this.institutionGroupsSubscription.unsubscribe();
            this.institutionGroupsSubscription = this.refreshList(optionsInterface);

            // todo fix reload until paginator function work
            /*
            this.paginatedCollection.paginator.page = optionsInterface.page;
			this.paginatedCollection.paginator.filter = optionsInterface.filter;
			*/
        }
    }

    refreshList(optionsInterface): Subscription {

        return this.getInstitutionGroupAdresses(optionsInterface)
            .subscribe(data => {
                this.allInstitutions = [];
                this.allInstitutionsFormated = [];
                this.institutionGroupsCollection = {};

                if (!data) {
                    return;
                }

                for (const entity of data.entities) {
                    const institution: InstitutionGroupAddress = {
                        id: parseInt(entity.id.toString(), 10),
                        institutiongroupname: entity.get('label'),
                        address: entity.get('address'),
                        city: entity.get('city'),
                        postalCode: entity.get('postalCode'),
                        country: entity.get('country'),
                    };

                    this.institutionGroupsCollection[entity.id] = entity;

                    // used to show all institution entities.
                    this.allInstitutions.push(institution);

                    // used to show all institution entities FORMATED.
                    this.allInstitutionsFormated.push(this.getAllFields(institution));
                }

                if (this.refreshListCallback) {
                    this.refreshListCallback();
                }
            });
    }

    addInstitutionGroup(group): Observable<DataEntity> {
        const groups = [];

        if (group && group.institution) {
            const id = group.institution.id;
            if (this.userData.get('groups').indexOf(id) === -1) {
                groups.push(id);

            }
        }

        groups.push(...this.userData.get('groups'));
        this.userData.set('groups', groups);

        const obs = this.userData.save();
        obs.subscribe((data) => {
            this.communicationCenter.getRoom('userProfileEdit')
                .getSubject('userData')
                .next(data);
        });

        return obs;
    }

    deleteInstitutionGroup(group): Observable<DataEntity> {
        const groups = [];
        let index = null;

        groups.push(...this.userData.get('groups'));
        index = this.userData.get('groups').indexOf(group.id.toString());

        if (index !== -1) {
            groups.splice(index, 1);

            this.userData.set('groups', groups);
            return this.userData.save().pipe(
                tap(data => {
                    this.communicationCenter.getRoom('userProfileEdit')
                        .getSubject('userData')
                        .next(data);
                }));
        }
    }

    getSubEntity<T, U extends keyof T>(field: U, data: T): string | T[U] {
        return data ? data[field] : '';
    }

    getAllFields(institution: InstitutionGroup | InstitutionGroupAddress): Institution {
        let stringToFilter = '';

        stringToFilter += ' ' + institution['institutiongroupname'];
        stringToFilter += ' (' + institution['address'];
        stringToFilter += ' ' + institution['city'];
        stringToFilter += ' ' + institution['postalCode'];
        stringToFilter += ' ' + institution['country'] + ')';

        const id = institution['id'].toString();
        const hideCode = institution['hideCode'];
        const code = institution['code'];
        const name = institution['institutiongroupname'];
        const field = stringToFilter;

        return {id, hideCode, code, name, field};
    }

    resetOptionInterfaces(): void {
        this.optionsInterface = {
            filter: {},
            page: 1,
            range: 10
        };

        this.launchSearch(this.unsubscribeAll, this.optionsInterface);
    }

    unsubscribeAll(): void {
        if (this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription.unsubscribe();
            this.institutionGroupsSubscription = null;
        }
        if (this.institutionInUserSubscription) {
            this.institutionInUserSubscription.unsubscribe();
            this.institutionInUserSubscription = null;
        }
    }

    public isUserHasManagerRightsInHisInstitutions(user: UserDataEntity): boolean {
        return this.institutionInUserData.length > 0 && this.institutionInUserData.every(institution =>
            institution.admins.some(admin =>
                admin.uid.toString() === user.id.toString()
                && _.has(admin.roles, INSTITUTION_MANAGER_ROLE_ID)
            )
        );
    }

    /**
     * Same as {@link isUserHasEducatorRightsInHisInstitutions} but boolean is emitted every time {@link onInstitutionGroupsChanged} is emitted
     * @param user
     */
    public isUserHasEducatorRightsInHisInstitutions$(user: UserDataEntity): Observable<boolean> {
        return this.onInstitutionGroupsChanged.pipe(
            map(() => {
                return this.institutionInUserData.every(institution => {
                    return institution.admins.some(admin =>
                        admin.uid.toString() === user.id.toString()
                        && _.has(admin.roles, INSTITUTION_EDUCATOR_ROLE_ID)
                    );
                });
            })
        );
    }

    public isUserHasEducatorRightsInHisInstitutions(user: UserDataEntity): boolean {
        return this.institutionInUserData.every(institution => {
            return institution.admins.some(admin =>
                admin.uid.toString() === user.id.toString()
                && _.has(admin.roles, INSTITUTION_EDUCATOR_ROLE_ID)
            );
        });
    }

    private postLogout(): void {
        if (this.institutionGroupsSubscription) {
            this.unsubscribeAll();
        }
    }

    private postAuthentication(): void {
        if (!this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription = this.refreshList(this.optionsInterface);

            // get institutions from Institutions endpoint to retrieve institutions in user 's group
            if (!this.institutionInUserSubscription) {
                this.getUserInstitutions();
            }
            this.getPostalCodeCollection()
                .subscribe((data) => {
                    const postalCodesMap = data.entities.map((entity) => {
                        const postalCodes = entity.get('postalCodes');
                        return postalCodes[0];
                    });
                    this.postalCodes = postalCodesMap[0];
                });
        }
    }

    public isAnyInstitutionCodeClassDisabled(): boolean {
        return this.institutionInUserData.some((inst) => inst.hideCode === true );
    }

    public toggleCodeClassStatus(idInst: number | string): Observable<boolean> {
        const institutionEntity = this.institutionsInUserDataCollection[idInst];
        if (!!institutionEntity) {
            institutionEntity.set('hideCode', !institutionEntity.get('hideCode'));
            const obs = institutionEntity.save();
            obs.subscribe(() => this.getUserInstitutions());
            return obs;
        }
        throw new Error('institutionEntity with the given id was not loaded');
    }

    /**
     * save the new state for hiding or not code for joining institution
     * @param idInst id institution (shool)
     * @param state hide code true or false
     */
    public setInstCode(idInst: number | string, state: boolean): Observable<boolean> {
        const institutionEntity = this.institutionsInUserDataCollection[idInst];
        if (!!institutionEntity) {
            institutionEntity.set('hideInstCode', state);
            const obs = institutionEntity.save();
            obs.subscribe(() => this.getUserInstitutions());
            return obs;
        }
        throw new Error('institutionEntity with the given id was not loaded seting institution code error');
    }

    public createInstitution(data: {[key: string]: any}): Observable<InstitutionDataEntity> {
        return this.octopusConnect.createEntity('institutions', data) as Observable<InstitutionDataEntity>;
    }
}
