import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {brand} from '../../../../../settings';

@Component({
    selector: 'app-demo-choice',
    templateUrl: './demo-choice.component.html',
})

export class DemoChoiceComponent {
    public brand = '';

    constructor(private router: Router) {
        this.brand = brand;
    }

    playJunior(): void {
        this.router.navigate(['demo', 'junior'], {queryParamsHandling: 'preserve'});
    }

    playCollege(): void {
        this.router.navigate(['demo', 'explorer'], {queryParamsHandling: 'preserve'});
    }

    playPython(): void {
        this.router.navigate(['demo', 'backend-driven'], {queryParamsHandling: 'preserve'});
    }
}
