import {Component, Inject, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatCard, MatCardContent, MatCardFooter} from '@angular/material/card';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-redirection-modal',
    templateUrl: './mobile-redirection-modal.component.html',
    styleUrls: ['./mobile-redirection-modal.component.scss'],
    imports: [
        MatDialogTitle,
        TranslateModule,
        MatIcon,
        MatCard,
        MatCardContent,
        MatCardFooter,
        MatDialogActions,
        MatButton
    ],
    standalone: true
})
export class MobileRedirectionModalComponent {

    constructor( @Inject(MAT_DIALOG_DATA) public data: {[key: string]: any},
                 private dialogRef: MatDialogRef<MobileRedirectionModalComponent>) { }

    public close(): void {
        this.dialogRef.close(true);
    }

}