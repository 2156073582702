import {take} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';

@Injectable()
export class IsUserLogged {

    private connector = inject(OctopusConnectService);
    private authService = inject(AuthenticationService);

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.pipe(take(1)).subscribe((userData: DataEntity) => {
            subject.next(true);
        }, (err) => {
            console.warn('User is not logged in', err);
            subject.next(false);

            this.authService.goToLogin(state);
        });

        return subject;
    }
}
