import {Injectable, inject} from '@angular/core';
import {OctopusConnectService} from 'octopus-connect';
import {shareReplay, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

export type InstanceVariablesAttributes = {
    demoUrls: string[];
}

export type InstanceVariablesEntity = TypedDataEntityInterface<InstanceVariablesAttributes>;

@Injectable({
    providedIn: 'root'
})
export class InstanceVariablesService {
    private octopusConnect = inject(OctopusConnectService);
    private instanceVariablesSubject = this.octopusConnect.loadCollection('variables/instance').pipe(
        map(collection => collection.entities[0]),
        shareReplay(1)
    ) as Subject<InstanceVariablesEntity>;

    public instanceVariables$ = this.instanceVariablesSubject?.asObservable();
}
