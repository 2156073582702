import {Injectable, inject} from '@angular/core';
import {activitiesModuleSettings} from '@modules/activities/core/lessons/services/activities-module.settings';
import {Type} from '@modules/activities/core/lessons/services/lessons.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash-es';
import {get} from 'lodash-es';
import {ModelSchema} from 'octopus-model';
import {FileMime} from 'shared/models';
import {Roles, RolesOrDefault} from 'shared/models/roles';
import {getSettingByRole, SwitchSettingsType} from 'shared/utils/settings';
import {modulesSettings} from '../../../../../settings';

const settingsStructure: ModelSchema = new ModelSchema(activitiesModuleSettings);
type SwitchedType = SwitchSettingsType<typeof activitiesModuleSettings>;

type OverrideType = {
    accessMatrix: { [key in 'lessonsListing' | 'activitiesListing']: { ['view']: Roles[] } },
    actionButtonsInMultiMatrix: { [key in TypologyLabel | 'default']: string[] },
    activitiesDisplayedColumns: string[],
    activitiesTypesUserCanUse: TypologyLabel[],
    activityOptionsEditable: string[],
    allowedActivityTypes: TypologyLabel[],
    allowedMediaTypeCombination: string[][],
    allowedRolesForAutoAssignmentCreation: Roles[],
    allowedRolesForModelsAssignation: Roles[],
    allowedRolesForModelsCreation: Roles[],
    allowedThumbnailExtensions: FileMime[],
    autoReadableActivities: {
        [key in TypologyLabel | 'default']: ('wording' | 'instruction')[]
    }
    availableAddButtons: { [key in RolesOrDefault]: string[] },
    bannerInfoAddUsages: { [granuleId: string]: 'autonomie' | 'collectif' },
    buttons: any, // TODO
    cardFieldsForPreview: string[],
    columns: { [key in RolesOrDefault]: string[] },
    dynamicRewardMilestones: number[],
    filters: { [key in RolesOrDefault]: string[] },
    filtersEditorActivitiesList: string[],
    filtersToClearOnSearchFormChange: string[],
    filtertoApplyOnLessonsByUrl: { url: string, id: number }[],
    gradeCalculation: {
        [key in TypologyLabel]: {
            right: number,
            wrong: number,
            missingRight: number,
            missingWrong: number,
        }
    }
    hideTopBarForSpecificActivitiesTypes: TypologyLabel[],
    hideUserActionButtonsByRole: RolesOrDefault[],
    initLoadLessonFilter: ('author' | 'role')[]
    isArrowNavigationEnabled: Roles[],
    isLessonDuplicationEnabled: { [key in RolesOrDefault]: boolean },
    isLessonEditionEnabled: { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    isLessonPreviewAccessible: { [key in RolesOrDefault]: boolean },
    lessonDialogFields: { [key in RolesOrDefault]: string[] },
    lessonDialogRequiredFields: { [key in RolesOrDefault]: string[] },
    lessonMetadataDialogFields: { [key in RolesOrDefault]: string[] },
    lessonStep: {
        typeSteps: (Type & { limit: number, markers: boolean, openMarkerAutomatic: boolean, markersLimit: number })[],
        outsidePollColor: string,
        insidePollColor: string,
        markerFields: string[]
    },
    levels: { primary: string[], secondary: string[] },
    menu: {
        models: boolean,
    },
    menuLessonsDeployable: { [key in RolesOrDefault]: string | unknown[] },
    openDialogInfoAfterDuplicateLesson: { [key in RolesOrDefault]: boolean },
    periodBackGround: { id: string | number, background: string }[],
    plugins: {
        [key: string]: {
            iconIdentifier: string,
            label: string,
            octopusConnectRoom: string
            pluginType: 'lessonTool',
        },
    },
    recommendationDisplayedColumns: string[],
    rolesCanShowBannerInfo: string[],
    routeOnExitLesson: string[],
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: TypologyLabel[], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: boolean // si chaque activités ou souslesson du parcours doit être sauvegardé
    },
    saveOnDestroy: (keyof typeof TypologyLabel)[],
    searchFields: { [path in string | 'default']: { [key in RolesOrDefault]: string[] } },
    showGenericProgressBar: boolean | { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    showMultiZoneProgressBar: boolean | { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    stepper: {
        [key in (Roles | 'default')]: {
            align: string,
            position: string,
            showRecap: boolean,
            stepValidationNeedSave: boolean
        }
    },
    symbolsForLatexKeyboard: any, // todo
    typeActivitiesToSkip: TypologyLabel[],
    urlsDemos: { id: 'junior' | 'explorer', url: string }[],
    urlVideoException: string[],
}

type SettingsType = Omit<SwitchedType, keyof OverrideType> & OverrideType;

@Injectable({
    providedIn: 'root'
})
export class LessonsConfigurationService {

    private authenticationService = inject(AuthenticationService);

    public readonly settings = <SettingsType>settingsStructure.filterModel(modulesSettings.activities);

    public get isListFormatStepper(): boolean {
        return this.settings.isListFormatStepper && this.authenticationService.isAtLeastTrainer();
    }

    /**
     * Defined if in this instance it must check license in institution
     */
    public isInstanceMustCheckLicense(): boolean {
        return get(this.settings, 'isInstanceMustCheckLicense', false);
    }

    /**
     * if page list of activities must be displayed
     */
    public isActivitiesListMustBeDisplayed(): boolean {
        return get(this.settings, 'isActivitiesListMustBeDisplayed', false);
    }

    public getStepperConfig(assignmentType = 'default'): { position: string; align: string; isStepValidationNeedSave: boolean; isRecapActive: boolean } {
        if (this.settings.stepper === null) {
            return null;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.settings.stepper?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.settings.stepper[targetRole] === null) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.settings, `stepper.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        if (this.settings.stepper[targetRole][assignmentTarget] === null) {
            return null;
        }

        return {
            align: get(this.settings, `stepper.${targetRole}.${assignmentTarget}.align`, 'start'),
            position: get(this.settings, `stepper.${targetRole}.${assignmentTarget}.position`, 'left'),
            isRecapActive: get(this.settings, `stepper.${targetRole}.${assignmentTarget}.showRecap`, true),
            isStepValidationNeedSave: get(this.settings, `stepper.${targetRole}.${assignmentTarget}.stepValidationNeedSave`, true),
        };
    }

    public isMultiZoneProgressBarActive(assignmentType = 'default'): boolean {
        if (this.settings.showMultiZoneProgressBar === false
            || this.settings.showMultiZoneProgressBar === true) {
            return this.settings.showMultiZoneProgressBar;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.settings.showMultiZoneProgressBar?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.settings.showMultiZoneProgressBar[targetRole] === true
            || this.settings.showMultiZoneProgressBar[targetRole] === false) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.settings, `showMultiZoneProgressBar.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        return get(this.settings, `showMultiZoneProgressBar.${targetRole}.${assignmentTarget}`, false);
    }

    public isGenericProgressBarActive(assignmentType = 'default'): boolean {
        if (this.settings.showGenericProgressBar === false
            || this.settings.showGenericProgressBar === true) {
            return this.settings.showGenericProgressBar;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.settings.showGenericProgressBar?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.settings.showGenericProgressBar[targetRole] === true
            || this.settings.showGenericProgressBar[targetRole] === false) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.settings, `showGenericProgressBar.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        return get(this.settings, `showGenericProgressBar.${targetRole}.${assignmentTarget}`, false);
    }

    /**
     * is the stepper allowed free navigation in assignement mode or just the step after the
     * previous already done
     */
    public isStepperAllowedAllSteps(): boolean {
        return this.settings.isStepperAllowedAllStep || false;
    }

    /**
     * show or not the tips zone in list-of-activities-component
     */
    public isDisplayTipsInListOfActivities(): boolean {
        return this.settings.displayTipsInListOfActivities || false;
    }

    /**
     * start player in app ?
     */
    public openLessonInApp(): boolean {
        return _.get(this.settings, 'openLessonInApp', false);
    }

    /**
     * afficher le clavier latex?
     */
    public displayLatexKeyboard(): boolean {
        return _.get(this.settings, 'latexKeyboard', true);
    }

    /**
     * if true, display header banner info
     */
    public rolesCanShowBannerInfo(): string[] {
        return this.settings.rolesCanShowBannerInfo;
    }

    /**
     * if true, display header banner info
     */
    public bannerInfoMustListenUsages(): { [key: string]: string } {
        return get(this.settings, 'bannerInfoAddUsages', null);
    }

    /**
     * Récupère les filtres à utiliser pour une route et un rôle d'utilisateur donnés.
     *
     * @param routeKey La clé de la route pour laquelle récupérer les filtres.
     * @param userRole Le rôle de l'utilisateur pour lequel récupérer les filtres.
     * @returns Un tableau de chaînes de caractères contenant les noms des filtres à utiliser.
     */
    getSearchFields(routeKey: string, userRole: string): string[] {
        const searchFields = _.get(this.settings, 'searchFields', {});
        const defaultFilters = searchFields['default'].default || [];
        const routeFilters = routeKey in searchFields ? searchFields[routeKey] : {default: defaultFilters};
        const userFilters = userRole in routeFilters ? routeFilters[userRole] : routeFilters.default;
        return Array.isArray(userFilters) ? [...userFilters] : [];
    }


    /**
     * Récupère pour le role actif la liste des éléments déployables dans le menu lessons
     * Les éléments sont :
     * - soit des id d'éléments (que l'on retrouve dans mappingList),
     * - soit des objets de type FuseNavigationItem ou assimilé
     * @param mappingList liste des FuseNavigationItem ou assimilé identifiables par un id (id: string|number) que l'on trouve dans le setting
     */
    public getDeployableChildrenLessonItems<T extends { id: string | number }>(mappingList?: T[]): T[] {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.settings, `menuLessonsDeployable`, {default: []});
        let settingValueForRole = settingValue[role];
        if (settingValueForRole === undefined) {
            settingValueForRole = settingValue.default;
        }

        if (Array.isArray(settingValueForRole) === false) {
            throw new Error(`menuLessonsDeployable setting is not an array or a Role-based array`);
        }

        return settingValueForRole.map((item: string | T) => {
            if (typeof item === 'string') {
                return mappingList.find((mappingItem) => mappingItem.id === item);
            }
            return item;
        });
    }

    public isLessonEditionEnabled(): boolean {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.settings, `isLessonEditionEnabled`, {default: false});
        const settingValueForRole = settingValue[role];
        return settingValueForRole === undefined ? settingValue.default : settingValueForRole;
    }

    /**
     * if true, display header banner info
     */
    public periodBackGroundToUse(idConcept: string): string {
        const periodBackgroundList = get(this.settings, 'periodBackGround', [{id: '4119', background: 'english--background'}, {
            id: '4068',
            background: 'spanish--background'
        }]);
        const pb = periodBackgroundList.filter((pb: { id: string, background: string }) => pb.id.toString() === idConcept.toString());
        return pb ? pb[0].background : 'english--background';
    }

    /**
     * Si l'on démarre un parcours sans avoir au préalable chargé une assignation, ce setting permet de définir si oui ou non on a le droit de poursuivre (et si non, on doit quitter le parcours). Ce genre de cas arrive quand on recharge un parcours, on perd l'assignation
     */
    public shouldExitIfNoAssignment() {
        return this.settings.shouldExitIfNoAssignment;
    }

    /**
     * liste des id de filtre pour la requete permettant de savoir le nombre d'assignations
     * que l'utilisateur a.
     */
    public getIdFilterToUseInMyLessonButton(): string {
        return get(this.settings, 'idFilterToUseInPeriodListMyLessonButton', '2683,2681,4270');
    }

    public isLessonDuplicationEnabled(): boolean {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.settings, `isLessonDuplicationEnabled`, {default: false});
        const settingValueForRole = settingValue[role];
        return settingValueForRole === undefined ? settingValue.default : settingValueForRole;
    }


    public isLessonPreviewAccessible(accessLevel: Roles) {
        return getSettingByRole(this.settings.isLessonPreviewAccessible, accessLevel);
    }

    public getLessonMetadataDialogFields(role: Roles) {
        return getSettingByRole(this.settings.lessonMetadataDialogFields, role);
    }

    public getAvailableAddButtons(accessLevel: Roles) {
        return getSettingByRole(this.settings.availableAddButtons, accessLevel);
    }

    public isImageFullscreenButton(): boolean {
        return get(this.settings, 'imageFullscreenButton');
    }

    public getExtraActionAfterUserSave(): boolean {
        return get(this.settings, 'extraActionAfterUserSave', false);
    }

    public isTtsEnabled(): boolean {
        return get(this.settings, 'isTtsEnabled', true);
    }

}
